<template>
  <section v-loading="modalLoading" class="main-form">
    <el-scrollbar wrap-style="max-height: 400px;max-width: 400px">
      <div>
        <label>Stat Icon</label>
        <br />
        <el-button
          type="text"
          class="text-center ml-3"
          @click="iconPickerdialogVisible = true"
        >
          <div
            v-if="isApplicationUser"
            :style="`background: ${iconBgColor} !important;`"
            class="circleInDialog"
          >
            <img
              :src="
                require('@/assets/img/icons/' +
                  getParentPath(addStatsData.icon_category) +
                  '/' +
                  addStatsData.icon)
              "
              alt="menu"
              height="22"
              width="23"
              style="margin-top: 6px"
              v-if="addStatsData.icon"
            />
            <img
              src="@/assets/img/icons/menuApplicationUsersWhite/dashboard.svg"
              alt="menu"
              style="margin-top: 6px"
              v-else
            />
          </div>
          <div v-else>
            <img
              :src="require('@/assets/img/icons/menu/' + addStatsData.icon)"
              alt="menu"
              width="35"
              v-if="addStatsData.icon"
            />

            <img
              src="@/assets/img/icons/menu/dashboard.svg"
              alt="menu"
              width="35"
              v-else
            />
          </div>
        </el-button>
        <br />
        <label>Component name</label>
        <el-input
          v-model="addStatsData.component_name"
          placeholder="Enter component name"
          class="mb-1"
        ></el-input>
        <div style="display: flex" v-if="addStatsData.type == 'STAT'">
          <el-radio-group
            v-model="addStatsData.display_type"
            @change="showNumberFields"
          >
            <el-radio label="count">Show count</el-radio>
            <el-radio label="sum">Show sum</el-radio>
            <el-radio label="FORMULA">Show formula</el-radio>
          </el-radio-group>
        </div><br>
        <el-checkbox v-if="addStatsData.type == 'STAT'" v-model="addStatsData.isVisible">Is Visible</el-checkbox>
        <label v-if="addStatsData.type == 'STAT'">Background color:</label>
        <el-color-picker size="mini" v-model="addStatsData.background_color" v-if="addStatsData.type == 'STAT'" class="ml-1"></el-color-picker>
        <div v-if="addStatsData.type == 'ENTITY_VIEW'">
          <label>Entity Views</label><br>
          <el-select clearable filterable v-model="addStatsData.entity_view_id" style="width:100%" @change="autoFillComponentName">
            <el-option v-for="(view, index) in getAllEntityViews.data" :key="index" :label="view.name" :value="view._id">
            </el-option>
          </el-select>
        </div>
        <template v-if="addStatsData.display_type == 'FORMULA'">
          <div v-if="!refreshFormula">
            <h4 class="f-weight-500 h4 mt-15 mb-05">Art Board</h4>
            <div class="artboard">
              <div
                v-for="(item, index) of addStatsData.selected_fields"
                v-bind:key="index"
              >
                <el-button
                  v-if="showFieldButton(item.type)"
                  size="medium"
                  class="btn-variable"
                  @click="swapFields(index)"
                  :class="getActiveClass(index)"
                >
                  <span v-if="item.type == 'OPERATOR'">{{
                    item.operator
                  }}</span>

                  <span v-if="item.type == 'FIELD'">{{ item.field_name }}</span>
                  <span v-if="item.type == 'PARENTHESES'">{{
                    item.parentheses
                  }}</span>
                  <span v-if="item.type == 'FORMULA'">{{ item.formula }}</span>
                  <span v-if="item.type == 'NUMBER'">{{ item.number }}</span>
                  <span v-if="item.type == 'CURRENCY'">{{
                    item.currency
                  }}</span>
                  <span v-if="item.type == 'DATE'">{{ item.date }}</span>
                  <span v-if="item.type == 'TIME'">{{ item.time }}</span>
                  <span v-if="item.type == 'DATE_TIME_RANGE'">{{
                    item.date_time_range
                  }}</span>
                  <span v-if="item.type == 'DATE_RANGE'">{{
                    item.date_range
                  }}</span>
                  <span v-if="item.type == 'LIST'">{{ item.list }}</span>
                  <span v-if="item.type == 'GLOBAL_VARIABLE'">{{
                    item.field_name
                  }}</span>
                </el-button>
                <el-button
                  v-if="item.type == 'OPERATOR'"
                  :type="getOperationType(item.operator)"
                  size="mini"
                  class="btn-operation"
                  @click="swapFields(index)"
                  circle
                  :class="getActiveClass(index)"
                >
                  <img
                    v-if="item.operator == '+'"
                    src="@/assets/img/calculator/plus.svg"
                    alt="brackets-left"
                  />
                  <img
                    v-if="item.operator == '-'"
                    src="@/assets/img/calculator/minus.svg"
                    alt="brackets-left"
                  />
                  <img
                    v-if="item.operator == '*'"
                    src="@/assets/img/calculator/close.svg"
                    alt="brackets-left"
                  />
                  <img
                    v-if="item.operator == '/'"
                    src="@/assets/img/calculator/divider.svg"
                    alt="brackets-left"
                  />
                </el-button>
                <el-button
                  v-if="item.type == 'PARENTHESES' && item.parentheses == '('"
                  type="text"
                  effect="dark"
                  class="text-button"
                  >(</el-button
                >
                <el-button
                  v-if="item.type == 'PARENTHESES' && item.parentheses == ')'"
                  type="text"
                  effect="dark"
                  class="text-button"
                  >)</el-button
                >
              </div>
            </div>
            <div>
              <div class="variables-fields">
                <el-form>
                  <el-row :gutter="10">
                    <el-col :md="12" :sm="12" :lg="8">
                      <el-form-item label="Fields">
                        <el-select
                          v-model="showSelectField"
                          :disabled="checkDisabled('field')"
                          @change="onSelectField"
                          placeholder="Select Fields"
                        >
                          <el-option
                            v-for="item of getFields"
                            :key="item.keyValue"
                            :label="item.component_name"
                            :value="item.keyValue"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <!-- <el-col :md="12" :sm="12" :lg="8">
                      <el-form-item label="Global Variable">
                        <el-select
                          v-model="showSelectGlobalVariable"
                          @change="onSelectGlobalVariable($event)"
                          placeholder="Select Global variable"
                          :disabled="checkDisabled('global_variable')"
                        >
                          <el-option
                            v-for="item of getGlobalVariables()"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col> -->

                    <el-col :md="12" :sm="12" :lg="8">
                      <el-form-item label="Numbers">
                        <el-input
                          v-model="manualNumber"
                          placeholder="Enter Number"
                          :disabled="checkDisabled('number')"
                        >
                          <el-button
                            slot="append"
                            type="primary"
                            icon="el-icon-plus"
                            @click="addNumber"
                            :disabled="manualNumber < 0"
                          ></el-button>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <div class="operaters">
                <el-button-group class="arthimetic-opeations">
                  <el-button
                    type="success"
                    @click="onSelectOperator('+')"
                    class="btn-operation"
                    size="mini"
                    :disabled="checkDisabled('operator')"
                  >
                    <img
                      src="@/assets/img/calculator/plus.svg"
                      alt="brackets-left"
                    />
                  </el-button>
                  <el-button
                    type="primary"
                    @click="onSelectOperator('-')"
                    class="btn-operation"
                    size="mini"
                    :disabled="checkDisabled('operator')"
                  >
                    <img
                      src="@/assets/img/calculator/minus.svg"
                      alt="brackets-left"
                    />
                  </el-button>
                  <el-button
                    type="info"
                    @click="onSelectOperator('*')"
                    class="btn-operation"
                    size="mini"
                    :disabled="checkDisabled('operator')"
                  >
                    <img
                      src="@/assets/img/calculator/close.svg"
                      alt="brackets-left"
                    />
                  </el-button>
                  <el-button
                    type="warning"
                    @click="onSelectOperator('/')"
                    class="btn-operation"
                    size="mini"
                    :disabled="checkDisabled('operator')"
                  >
                    <img
                      src="@/assets/img/calculator/divider.svg"
                      alt="brackets-left"
                    />
                  </el-button>
                </el-button-group>
                <el-button-group class="brackets-btn-group">
                  <el-button
                    type="info"
                    @click="onSelectParentheses('(')"
                    class="btn-operation"
                    size="mini"
                    :disabled="checkDisabled('parentheses', '(')"
                    >(</el-button
                  >
                  <el-button
                    type="info"
                    @click="onSelectParentheses(')')"
                    class="btn-operation"
                    size="mini"
                    :disabled="checkDisabled('parentheses', ')')"
                    >)</el-button
                  >

                  <el-button
                    type="danger"
                    @click="popField()"
                    class="btn-operation"
                    size="mini"
                    :disabled="
                      addStatsData &&
                      addStatsData.selected_fields &&
                      addStatsData.selected_fields.length < 1
                        ? true
                        : false
                    "
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="!selectedEntityRelationalData && !selectedEntityNestedRelationalData && addStatsData.type !== 'ENTITY_VIEW'" class="mt-1">
            <label>Entities</label>
            <el-select
              v-model="addStatsData.entity_id"
              no-data-text="No Entities available"
              filterable
              clearable
              default-first-option
              @change="getComponentInfo"
              style="width: 100%"
              placeholder="Entity"
            >
              <el-option
                v-for="(entity, index) of getAllEntitiesData"
                :value="entity._id"
                :key="index"
                :label="entity.name"
              >
                <span style="float: left">{{ entity.name }}</span>
              </el-option>
            </el-select>
            <el-select
              placeholder="Group by"
              v-model="addStatsData.table_field_group_by"
              v-if="addStatsData.type == 'TABLE'"
              clearable
              @change="getSelectedFieldOptions"
              style="width: 100%"
              class="mt-1"
            >
              <el-option
                v-for="(field, index) of getTemplateFields.filter(
                  (e) => e.input_type != 'DATE'
                )"
                :value="field.key"
                :key="field.key + index"
                :label="`${field.template_name} - ${field.label} `"
              >
                <span>{{ field.template_name }} - {{ field.label }}</span>
              </el-option>
            </el-select>
            <el-select
              placeholder="Sub group by"
              v-model="addStatsData.table_field_sub_group_by"
              v-if="addStatsData.type == 'TABLE'"
              clearable
              @change="getSelectedFieldOptions"
              style="width: 100%"
              class="mt-1"
            >
              <el-option
                v-for="(field, index) of getTemplateFields.filter(
                  (e) =>
                    e.input_type != 'DATE' &&
                    e.input_type != 'MULTI_SELECT' &&
                    e.key != addStatsData.table_field_group_by
                )"
                :value="field.key"
                :key="field.key + index"
                :label="`${field.template_name} - ${field.label} `"
              >
                <span>{{ field.template_name }} - {{ field.label }}</span>
              </el-option>
            </el-select>
            <el-select
              placeholder="Select options to group"
              multiple
              v-model="addStatsData.table_field_group_by_selected_options"
              v-if="addStatsData.table_field_sub_group_by"
              :clearable="true"
              class="mt-1"
              collapse-tags
              style="width: 100%"
            >
              <template
                v-if="
                  checkFieldType(
                    addStatsData.table_field_sub_group_by,
                    'ENTITY'
                  )
                "
              >
                <el-option
                  v-for="(data, index) of groupByFieldEntityValues"
                  :key="index + 'entity' + data._id"
                  :value="data._id"
                  :label="getLabel(data, index, groupByFieldEntity)"
                >
                  <span>{{ getLabel(data, index, groupByFieldEntity) }}</span>
                </el-option>
              </template>
              <template v-else>
                <el-option
                  v-for="(option, index) of pieChartFieldGroupByOptions"
                  :value="option"
                  :key="index"
                  :label="option"
                >
                  <span>{{ option }}</span>
                </el-option>
              </template>
            </el-select>
            <div v-if="addStatsData.table_field_group_by" class="mt-1">
              <el-tag
                v-for="(
                  field, index
                ) in addStatsData.table_field_group_by_fields"
                :key="index + 'name'"
                @close="handleClose(index)"
                style="margin-right: 5px; margin-top: 5px"
                :type="field && field.sub_group ? 'success' : 'primary'"
              >
                {{ field.name }}
              </el-tag>
              <!--               :closable="
                !addStatsData.table_field_group_by_fields ||
                !addStatsData.table_field_group_by_fields.length ||
                (addStatsData.table_field_group_by_fields &&
                  addStatsData.table_field_group_by_fields.length == 1)
                  ? false
                  : true
              "
              <el-link
              icon="el-icon-circle-plus-outline"
              :underline="false"
              type="primary"
              style="margin-left: 3px"
              @click="openTableColums"
            ></el-link> -->
            </div>
          </div>
          <br />
          <div v-if="(isFromApplicationuser || selectedEntityRelationalData) && !selectedEntityNestedRelationalData">
            <label>Relational entities</label>
            <el-select
              placeholder="Relational Entity"
              v-model="selectedEntityRelationalData"
              @change="getComponentInfo"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="(op, i) in getRelationshipEntities"
                :key="op._id + i"
                :value="op._id"
                :label="op.name"
                >{{ "My " + op.name }}</el-option
              >
            </el-select>
          </div>
          <div v-if="isFromApplicationuser && !selectedEntityRelationalData" class="mt-1">
            <label>Nested relational entities</label>
            <el-select
              placeholder="Nested Relational Entity"
              v-model="selectedEntityNestedRelationalData"
              @change="getComponentInfo"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="(op, i) in getNestedRelationshipEntities"
                :key="op.key + i"
                :value="op.key"
                :label="op.name"
                ></el-option
              >
            </el-select>
          </div>
          <label v-if="addStatsData.type == 'CHART'">Chart type</label>
          <el-select
            v-model="addStatsData.chart_type"
            filterable
            default-first-option
            class="mb-1"
            v-if="addStatsData.type == 'CHART'"
            style="width: 100%"
          >
            <el-option
              v-for="(field, index) of chartTypes"
              :value="field.value"
              :key="field.value + index"
              :label="field.label"
            >
              <span>{{ field.label }}</span>
            </el-option>
          </el-select>
          <label v-if="addStatsData.type == 'CHART'">Select field</label>
          <el-select
            v-model="addStatsData.pie_chart_field"
            filterable
            default-first-option
            class="mb-1"
            v-if="addStatsData.type == 'CHART'"
            style="width: 100%"
            @change="fetchFieldOptionsForChart(addStatsData.pie_chart_field)"
          >
            <el-option
              v-for="(field, index) of getTemplateFields"
              :value="field.key"
              :key="field.key + index"
              :label="`${field.template_name} - ${field.label}`"
            >
              <span>{{ field.template_name }} - {{ field.label }}</span>
            </el-option>
          </el-select>
          <label v-if="addStatsData.type == 'CHART' && addStatsData.pie_chart_field">Select options</label>
          <el-select
            placeholder="Select options for chart"
            multiple
            v-model="addStatsData.pie_chart_field_options"
            v-if="addStatsData.pie_chart_field"
            :clearable="true"
            collapse-tags
            style="width: 100%"
          >
            <template
              v-if="
                checkFieldType(addStatsData.pie_chart_field, 'ENTITY')
              "
            >
              <el-option
                v-for="(data, index) of pieChartFieldEntityValues"
                :key="index + 'entity' + data._id"
                :value="data._id"
                :label="getLabel(data, index, pieChartFieldEntity)"
              >
                <span>{{ getLabel(data, index, pieChartFieldEntity) }}</span>
              </el-option>
            </template>
            <template v-else>
              <el-option
                v-for="(option, index) of pieChartFieldSelectOptions"
                :value="option"
                :key="index"
                :label="option"
              >
                <span>{{ option }}</span>
              </el-option>
            </template>
          </el-select>
          <br />
          <label v-if="addStatsData.type == 'CHART'">Filter</label>
          <!-- chartFilters -->
          <el-select
            v-if="addStatsData.type == 'CHART'"
            placeholder="Select filter"
            clearable
            v-model="addStatsData.chart_selected_filter"
            style="width: 100%"
          >
            <el-option
              v-for="fl in chartFilters"
              :key="fl._id"
              :value="fl._id"
              :label="fl.filter_name"
            ></el-option>
          </el-select>
          <br />
          <label
            v-if="
              addStatsData.type == 'CHART' &&
              (!addStatsData.sum_fields ||
                !addStatsData.sum_fields.length ||
                addStatsData.sum_fields.length == 1)
            "
            >Group by</label
          >
          <el-select
            placeholder="Group by"
            v-model="addStatsData.pie_chart_field_group_by"
            :clearable="true"
            v-if="
              addStatsData.type == 'CHART' &&
              (!addStatsData.sum_fields ||
                !addStatsData.sum_fields.length ||
                addStatsData.sum_fields.length == 1)
            "
            @change="getSelectedFieldOptions"
            style="width: 100%"
          >
            <el-option
              v-for="(field, index) of getTemplateFields.filter(
                (e) => e.key != addStatsData.pie_chart_field
              )"
              :value="field.key"
              :key="field.key + index"
              :label="`${field.template_name} - ${field.label} `"
            >
              <span>{{ field.template_name }} - {{ field.label }}</span>
            </el-option>
          </el-select>
          <!-- include_chart_total -->
          <el-checkbox
            v-model="addStatsData.include_chart_total"
            v-if="addStatsData.pie_chart_field_group_by"
          >
            Include total
          </el-checkbox>
          <el-select
            placeholder="Select options to group"
            multiple
            v-model="addStatsData.pie_chart_field_group_by_selected_options"
            v-if="addStatsData.pie_chart_field_group_by"
            :clearable="true"
            class="mt-1"
            collapse-tags
            style="width: 100%"
          >
            <template
              v-if="
                checkFieldType(addStatsData.pie_chart_field_group_by, 'ENTITY')
              "
            >
              <el-option
                v-for="(data, index) of groupByFieldEntityValues"
                :key="index + 'entity' + data._id"
                :value="data._id"
                :label="getLabel(data, index, groupByFieldEntity)"
              >
                <span>{{ getLabel(data, index, groupByFieldEntity) }}</span>
              </el-option>
            </template>
            <template v-else>
              <el-option
                v-for="(option, index) of pieChartFieldGroupByOptions"
                :value="option"
                :key="index"
                :label="option"
              >
                <span>{{ option }}</span>
              </el-option>
            </template>
          </el-select>
          <br />
          <label v-if="addStatsData.type == 'CHART'">Show total</label>
          <el-select
            v-model="addStatsData.sum_fields"
            v-if="addStatsData.type == 'CHART'"
            placeholder="Total field"
            clearable
            :multiple="true"
            :multiple-limit="addStatsData.pie_chart_field_group_by ? 1 : null"
            collapse-tags
            style="width: 100%"
          >
            <el-option
              v-for="(field, index) of getTotalFields"
              :value="field.key"
              :key="field.key + index"
              :label="`${field.template_name} - ${field.label} `"
            >
              <span>{{ field.template_name }} - {{ field.label }}</span>
            </el-option>
          </el-select>
          <label v-if="addStatsData.type != 'CHART' && addStatsData.type != 'ENTITY_VIEW'">Filters</label>
          <el-select
            v-model="addStatsData.filter_id"
            no-data-text="No filters available"
            filterable
            default-first-option
            class="mb-1"
            v-if="addStatsData.type != 'CHART' && addStatsData.type != 'ENTITY_VIEW'"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(filter, index) of statEntityFilters"
              :key="'filter_' + index"
              :value="filter._id"
              :label="filter.filter_name"
            >
              <span style="float: left">{{ filter.filter_name }}</span>
            </el-option>
          </el-select>
          <br />
          <div
            v-if="
              addStatsData.type == 'TABLE' ||
              addStatsData.type == 'CHART' ||
              (addStatsData.type == 'STAT' &&
                allDashboardstats &&
                allDashboardstats.includeGlobalDateFilter)
            "
          >
            Apply date filter on this field:
            <br />
            <el-select
              placeholder="Select date field"
              v-model="addStatsData.date_filter_field"
              default-first-option
              style="width: 100%"
              clearable
            >
            
              <el-option
                v-for="(field, index) of getAllDateFields"
                :value="field.key"
                :key="field.key + '_stat_' + index"
                :label="`${field.template_name} - ${field.label} `"
              >
                <span>{{ field.template_name }} - {{ field.label }}</span>
              </el-option>
              <el-option value="created_at" label="Created Date">Created Date</el-option>
              <el-option value="updated_at" label="Updated Date">Updated Date</el-option>
            </el-select>
            <el-select
              v-if="addStatsData.date_filter_field && (addStatsData.type == 'TABLE')"
              placeholder="Pick default"
              v-model="addStatsData.default_date_filter"
              :size="'mini'"
              clearable
            >
              <el-option
                v-for="(el, index) in pickerOptions"
                :key="el.value + '_' + index"
                :value="el.value"
                :label="el.label"
                >{{ el.label }}
              </el-option>
            </el-select>
            <el-checkbox
            style="margin-left: 8px ;"
              v-model="addStatsData.ignore_year"
              v-if="addStatsData.date_filter_field"
              class="mt-1"
              >Show every year</el-checkbox
            >
          </div>
          <label
            v-if="
              addStatsData.display_type == 'sum' && addStatsData.type == 'STAT'
            "
            class="mt-1"
            >Sum of</label
          >
          <el-select
            v-if="
              addStatsData.display_type == 'sum' && addStatsData.type == 'STAT'
            "
            v-model="addStatsData.sum_field"
            placeholder="Select field"
            clearable
            @change="getFieldType"
            style="width: 100%"
          >
            <el-option
              v-for="(field, index) of getTemplateFields"
              :value="field.key"
              :key="field.key + '_stat_' + index"
              :label="`${field.template_name} - ${field.label} `"
            >
              <span>{{ field.template_name }} - {{ field.label }}</span>
            </el-option>
          </el-select>
        </template>
      </div>
    </el-scrollbar>
    <!-- <el-form :model="addStatsData" label-width="100px">
      <el-form-item label="Chart type" v-if="addStatsData.type == 'CHART'">
      </el-form-item>
      <el-form-item label="Filters" v-else> </el-form-item>
    </el-form>-->
    <el-dialog
      title="Select Stat Icon"
      :visible.sync="iconPickerdialogVisible"
      top="25px"
      width="1000px !important"
    >
      <div v-if="isApplicationUser" class="iconslistScrollable">
        <div>
          <el-input placeholder="Search by name" v-model="searchIcon" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <el-row>
          <el-tabs v-model="selectedCategory">
            <el-tab-pane
              :label="category.name"
              :name="category.key"
              v-for="(category, i) in menuIconsForApp"
              :key="category.key + '_' + i"
            ></el-tab-pane>
          </el-tabs>
        </el-row>

        <el-row :gutter="10" style="margin-top: 15px">
          <el-col
            :xs="4"
            :sm="3"
            :md="2"
            :lg="2"
            :xl="1"
            v-for="(icon, index) in getSelectedCategory"
            :key="index"
          >
            <div
              class="circle-icon"
              :style="`background: ${iconBgColor} !important;cursor: pointer`"
              @click="setIcon(icon)"
            >
              <!-- if we're using el-tooltip it's applying padding:15px from external css that's why it's not
            displaying the icon image in proper alignment.Hence I have used title attribute for img tag. -->
              <img
                :src="
                  require('@/assets/img/icons/' +
                    getParentPath(selectedCategory) +
                    '/' +
                    icon.url)
                "
                width="27"
                height="25"
                style="
                  margin-top: 10px !important;
                  margin-right: 2px !important;
                "
                :title="icon.name"
              />
              <!-- <el-tooltip
                effect="dark"
                :content="icon.name"
                placement="top-start"
                style="padding: 0;"
              >
                <img
                  :src="
                    require('@/assets/img/icons/' +
                      getParentPath(selectedCategory) +
                      '/' +
                      icon.url)
                  "
                  width="27"
                  height="25"
                  style="margin-top: 10px !important; margin-right: 2px !important;"
                />
              </el-tooltip> -->
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else class="iconslistScrollable">
        <el-row :gutter="0">
          <el-col
            :xs="4"
            :sm="3"
            :md="2"
            :lg="2"
            :xl="1"
            v-for="(icon, index) in menuIcons"
            :key="index"
            class="border-radius"
            style="cursor: pointer"
          >
            <div
              class="grid-content ep-bg-purple"
              v-on:click="setIconPath(icon)"
              style="cursor: pointer"
            >
              <el-tooltip
                effect="dark"
                :content="icon.name"
                placement="top-start"
              >
                <img
                  :src="require('@/assets/img/icons/menu/' + icon.url)"
                  :alt="icon.name"
                  width="25"
                />
              </el-tooltip>
              <!-- <p v-if="icon.name.length <= 8">{{ icon.name }}</p> -->
              <!-- <p v-else>{{ icon.name.slice(0, 6) }}...</p> -->
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog
      title="Add table columns"
      :visible.sync="openTableColumsModal"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      draggable
      :before-close="resetModal"
    >
      <el-row>
        <el-link
          icon="el-icon-circle-plus"
          type="primary"
          @click="addNewColumn"
          style="float: right"
          :underline="false"
          >Add</el-link
        >
      </el-row>
      <el-row>
        <el-scrollbar wrap-style="max-height: 250px; width: 100% !important;">
          <el-table :data="tableFieldGroupByFieldsModal" border>
            <el-table-column prop="Column" label="Column" width="180">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.key"
                  placeholder="Table columns"
                  clearable
                  @change="fillLabel(scope.row.key, scope.$index)"
                >
                  <el-option
                    label="Count"
                    value="count"
                    :disabled="
                      tableFieldGroupByFieldsModal.find((e) => e.key == 'count')
                        ? true
                        : false
                    "
                  >
                  </el-option>
                  <el-option
                    v-for="(field, index) of getTotalFields"
                    :value="field.key"
                    :key="field.key + index"
                    :label="`${field.template_name} - ${field.label} `"
                    :disabled="
                      tableFieldGroupByFieldsModal.find(
                        (e) => e.key == field.key
                      )
                        ? true
                        : false
                    "
                  >
                    <span>{{ field.template_name }} - {{ field.label }}</span>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="Contact Type"
              label="Column name"
              width="180"
            >
              <template slot-scope="scope">
                <el-input placeholder="Enter name" v-model="scope.row.name">
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="Actions" label="Actions" width="180">
              <template slot-scope="scope">
                <el-link
                  @click.native.prevent="
                    deleteRow(scope.$index, tableFieldGroupByFieldsModal)
                  "
                  type="danger"
                  icon="el-icon-delete"
                  :underline="false"
                ></el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openTableColumsModal = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="saveTableGroupBy"
          :disabled="checkTableGroupByField"
          >Save</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
var menuIconsData = require("@/assets/data/menu_icons.json");
var menuIconsDataApp = require("@/assets/data/menu_icons_application.json");

export default {
  mixins: [CustomDashboardConfig],
  props: {
    addStatsData: Object,
    allDashboardstats: Object,
    getRelationshipEntities: Array,
    getNestedRelationshipEntities: Array,
    isFromApplicationuser: Boolean,
    selectedEntity: Object,
    globalVariablesData: Array,
  },
  mounted() {
    console.log("addStatsData",this.addStatsData)
    this.fetchTemplateFields();
    if (this.addStatsData.entity_id) {
      if (this.addStatsData.is_relational_data) {
        this.selectedEntityRelationalData = this.addStatsData.entity_id;
      }
      if (this.addStatsData.is_nested_relational_data) {
        this.selectedEntityNestedRelationalData = this.addStatsData.selectedEntityNestedRelationalData;
      }
      this.fetchAllEntityFilters();
    }
    if (
      this.addStatsData.type == "STAT" &&
      this.addStatsData.display_type == "sum"
    ) {
      this.fetchTemplateFields(true, true);
    }
    if (this.getCompanyDetails) {
      this.iconBgColor = this.getCompanyDetails.button.background;
      this.iconColor = this.getCompanyDetails.icon_color;
    }

    //fetch entity group by fields
    if (
      this.addStatsData.type == "CHART" 
    ) {
      this.fetchFieldOptions(this.addStatsData?.pie_chart_field)
      if(this.addStatsData?.pie_chart_field_group_by){
        this.getSelectedFieldOptions(this.addStatsData.pie_chart_field_group_by);
      }
    }
    if (
      this.addStatsData.type == "TABLE" &&
      this.addStatsData.table_field_group_by &&
      this.addStatsData.table_field_sub_group_by
    ) {
      this.getSelectedFieldOptions(this.addStatsData.table_field_sub_group_by);
    }
  },
  data() {
    return {
      statEntityFilters: [],
      modalLoading: false,
      iconPickerdialogVisible: false,
      menuIcons: menuIconsData,
      menuIconsForApp: menuIconsDataApp.categories,
      iconBgColor: "",
      iconColor: "White",
      getTemplateFields: [],
      getAllDateFields: [],
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
      ],
      componentTypes: [
        {
          label: "Table",
          value: "TABLE",
        },
        {
          label: "Chart",
          value: "CHART",
        },
      ],
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
        {
          label: "Line",
          value: "LINE",
        },
      ],
      searchIcon: "",
      selectedCategory: "default",
      getTotalFields: [],
      selectedEntityRelationalData: "",
      selectedEntityNestedRelationalData: "",
      pieChartFieldGroupByOptions: [],
      pieChartFieldEntityValues : [],
      pieChartFieldEntity : null,
      pieChartFieldSelectOptions : [],
      groupByFieldEntityValues: [],
      groupByFieldEntity: null,
      chartFilters: [],
      tableFieldGroupByFieldsModal: [],
      openTableColumsModal: false,
      activeClassIndex: -1,
      showSelectOperator: "",
      showSelectField: "",
      manualNumber: "",
      activeClassName: "active",
      refreshFormula: false,
    };
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityCreatedStatus",
      "getEntityCreatedData",
      "getCustomDashboardStats",
      "getEntityDataById",
      "getEntityDataUpdateStatus",
      "getEntityErrors",
      "getAllEntities",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getContactLoginSlug",
    ]),
    ...mapGetters("entityViews", ["getAllEntityViews"]),
    getAllEntitiesData() {
      return this.getAllEntities && this.getAllEntities.data
        ? this.getAllEntities.data
        : [];
    },
    isApplicationUser() {
      return (
        this.$route.name == "entity-custom-dashboard-config" ||
        this.$route.name == "application-user-dashboard"
      );
    },
    getSelectedCategory() {
      let selectedIcons = this.menuIconsForApp.find(
        (e) => e.key == this.selectedCategory
      );
      if (selectedIcons?.icons) {
        if (this.searchIcon) {
          return selectedIcons.icons.filter((e) =>
            e.name.toLowerCase().includes(this.searchIcon.toLowerCase())
          );
        }
        return selectedIcons.icons;
      }
      return [];
    },
    checkTableGroupByField() {
      if (
        this.addStatsData?.table_field_group_by_fields &&
        this.addStatsData.table_field_group_by_fields.length
      ) {
        let empty = this.addStatsData.table_field_group_by_fields.find(
          (e) => !e || !e.name || !e.key
        );
        return empty ? true : false;
      }
      return (
        !this.addStatsData?.table_field_group_by_fields ||
        !this.addStatsData?.table_field_group_by_fields.length
      );
    },
    getFields() {
      if (this.allDashboardstats?.allStatComponents) {
        return this.allDashboardstats.allStatComponents.filter(e => e.display_type != 'FORMULA');
      }
      return [];
    },
  },
  methods: {
    autoFillComponentName(){
      this.addStatsData.component_name = this.getAllEntityViews.data.find(e=> e._id == this.addStatsData.entity_view_id).name
    },
    async fetchFieldOptionsForChart(field){
      this.addStatsData.pie_chart_field_options = []
      await this.fetchFieldOptions(field)
    },
    popField() {
      if(this.addStatsData?.selected_fields){
        this.addStatsData.selected_fields.pop();
      }
    },
    refreshFormulaData() {
      this.refreshFormula = true;
      setTimeout(() => {
        this.refreshFormula = false;
      });
    },
    onSelectParentheses(parentheses) {
      if (this.addStatsData?.selected_fields) {
        this.addStatsData.selected_fields.push({
          type: "PARENTHESES",
          parentheses,
        });
      } else {
        this.addStatsData["selected_fields"] = [
          { type: "PARENTHESES", parentheses },
        ];
      }
      this.showSelectParentheses = "";
      this.refreshFormulaData();
    },
    onSelectField(key) {
      let selectedField = this.getFields.find((e) => e.keyValue == key);
      if (!selectedField) {
        return;
      }
      let item = {
        key: selectedField.keyValue,
        field_name: selectedField.component_name,
        type: "FIELD",
      };
      if (item.formula) {
        item["type"] = "FORMULA";
      } else {
        item["type"] = "FIELD";
      }
      if (this.activeClassIndex > -1) {
        let fieldData =
          this.addStatsData.selected_fields[this.activeClassIndex];
        if (this.addStatsData?.selected_fields) {
          this.addStatsData.selected_fields.push(item);
        } else {
          this.addStatsData["selected_fields"] = [item];
        }
        this.showSelectField = "";
        if (
          fieldData &&
          (fieldData.type == "FIELD" ||
            fieldData.type == "FORMULA" ||
            fieldData.type == "NUMBER" ||
            fieldData.type == "DATE" ||
            fieldData.type == "TIME" ||
            fieldData.type == "DATE_TIME_RANGE" ||
            fieldData.type == "DATE_RANGE" ||
            fieldData.type == "LIST" ||
            fieldData.type == "GLOBAL_VARIABLE")
        ) {
          this.addStatsData.selected_fields[this.activeClassIndex] = item;
          this.showSelectField = "";
          this.activeClassIndex = -1;
        }
      } else {
        if (this.addStatsData?.selected_fields) {
          this.addStatsData.selected_fields.push(item);
        } else {
          this.addStatsData["selected_fields"] = [item];
        }
        this.showSelectField = "";
      }
      this.refreshFormulaData();
    },
    addNumber() {
      if (this.activeClassIndex > -1) {
        let fieldData =
          this.addStatsData.selected_fields[this.activeClassIndex];
        if (
          fieldData.type == "FIELD" ||
          fieldData.type == "FORMULA" ||
          fieldData.type == "NUMBER" ||
          fieldData.type == "DATE" ||
          fieldData.type == "CURRENCY" ||
          fieldData.type == "TIME" ||
          fieldData.type == "LIST" ||
          fieldData.type == "GLOBAL_VARIABLE"
        ) {
          let number = this.manualNumber;
          this.addStatsData.selected_fields[this.activeClassIndex] = {
            type: fieldData.type == "DATE" ? "DATE" : "NUMBER",
            number,
          };
          this.manualNumber = "";
          this.activeClassIndex = -1;
        }
      } else {
        if (this.manualNumber > 0) {
          let number = this.manualNumber;
          if (this.addStatsData?.selected_fields) {
            this.addStatsData.selected_fields.push({
              type: "NUMBER",
              number,
            });
          } else {
            this.addStatsData["selected_fields"] = [
              {
                type: "NUMBER",
                number,
              },
            ];
          }
          this.manualNumber = "";
        }
      }
      this.refreshFormulaData();
    },
    checkDisabled(type, item = "") {
      if (
        type == "field" ||
        type == "number" ||
        type == "currency" ||
        type == "global_variable" ||
        type == "date" ||
        type == "time" ||
        type == "list"
      ) {
        if (
          this.addStatsData?.selected_fields &&
          this.addStatsData.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.addStatsData.selected_fields[this.activeClassIndex];
            if (
              fieldData &&
              (fieldData.type == "FIELD" ||
                fieldData.type == "NUMBER" ||
                fieldData.type == "DATE" ||
                fieldData.type == "TIME" ||
                fieldData.type == "LIST" ||
                fieldData.type == "CURRENCY" ||
                fieldData.type == "GLOBAL_VARIABLE" ||
                fieldData.type == "FORMULA")
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "FIELD" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "NUMBER" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "DATE" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "TIME" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "DATE_TIME_RANGE" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "DATE_RANGE" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "LIST" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "GLOBAL_VARIABLE" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "FORMULA" ||
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "CURRENCY" ||
              (this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "PARENTHESES" &&
                this.addStatsData.selected_fields[
                  this.addStatsData.selected_fields.length - 1
                ].parentheses == ")")
            );
          }
        }
        return false;
      } else if (type == "operator") {
        if (
          this.addStatsData?.selected_fields &&
          this.addStatsData.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.addStatsData.selected_fields[this.activeClassIndex];
            if (fieldData && fieldData.type == "OPERATOR") {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "OPERATOR" ||
              (this.addStatsData.selected_fields[
                this.addStatsData.selected_fields.length - 1
              ].type == "PARENTHESES" &&
                this.addStatsData.selected_fields[
                  this.addStatsData.selected_fields.length - 1
                ].parentheses == "(")
            );
          }
        }
        return true;
      } else if (type == "parentheses") {
        if (this.activeClassIndex > -1) {
          return true;
        } else {
          let openParenthesesCount = 0;
          let lastOpenIndex = -1;
          let closeParenthesesCount = 0;
          let selectedFieldsLength =
            this.addStatsData &&
            this.addStatsData.selected_fields &&
            this.addStatsData.selected_fields.length
              ? this.addStatsData.selected_fields.length
              : 0;
          let index = -1;
          (this.addStatsData.selected_fields || []).map((el) => {
            index += 1;
            if (el.type == "PARENTHESES") {
              if (el.parentheses == "(") {
                openParenthesesCount += 1;
                lastOpenIndex = index;
              } else {
                closeParenthesesCount += 1;
              }
              return;
            }
          });
          let afterLastOpenLength = selectedFieldsLength - (lastOpenIndex + 1);
          if (!selectedFieldsLength) {
            return item == ")";
          } else if (selectedFieldsLength < 3) {
            //condition for formula
            return (
              this.addStatsData.selected_fields[selectedFieldsLength - 1]
                .type != "OPERATOR" || item == ")"
            );
          } else {
            if (item == "(") {
              if (
                this.addStatsData.selected_fields[selectedFieldsLength - 1]
                  .type == "OPERATOR"
              ) {
                return false;
              } else if (
                this.addStatsData.selected_fields[selectedFieldsLength - 1]
                  .type == "PARENTHESES" &&
                this.addStatsData.selected_fields[selectedFieldsLength - 1]
                  .parentheses == "("
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              if (
                this.addStatsData.selected_fields[selectedFieldsLength - 1]
                  .type == "OPERATOR"
              ) {
                return true;
              } else if (openParenthesesCount <= closeParenthesesCount) {
                return true;
              } else if (afterLastOpenLength < 2) {
                return true;
              } else if (
                afterLastOpenLength > 2 &&
                afterLastOpenLength % 2 == 0
              ) {
                if (
                  this.addStatsData.selected_fields[selectedFieldsLength - 1]
                    .type == "PARENTHESES" &&
                  this.addStatsData.selected_fields[selectedFieldsLength - 1]
                    .parentheses == ")"
                ) {
                  return false;
                }
                return true;
              } else {
                return false;
              }
            }
          }
        }
      } else {
        return false;
      }
    },
    onSelectOperator(operator) {
      if (
        this.activeClassIndex > -1 &&
        this.addStatsData.selected_fields[this.activeClassIndex].type ==
          "OPERATOR"
      ) {
        this.addStatsData.selected_fields[this.activeClassIndex] = {
          type: "OPERATOR",
          operator,
        };
        this.showSelectOperator = "";
        this.activeClassIndex = -1;
      } else {
        this.addStatsData.selected_fields.push({ type: "OPERATOR", operator });
        this.showSelectOperator = "";
      }
      this.refreshFormulaData();
    },
    getOperationType(operator) {
      if (operator == "+") {
        return "success";
      } else if (operator == "-") {
        return "primary";
      } else if (operator == "*") {
        return "info";
      } else if (operator == "/") {
        return "warning";
      }
    },
    getActiveClass(index) {
      if (this.activeClassIndex == index) {
        return "active";
      }
    },
    swapFields(index) {
      if (this.activeClassIndex > -1) {
        this.activeClassIndex = -1;
      } else {
        this.activeClassIndex = index;
      }
    },
    showFieldButton(type) {
      if (
        type == "FIELD" ||
        type == "NUMBER" ||
        type == "TIME" ||
        type == "DATE_TIME_RANGE" ||
        type == "DATE_RANGE" ||
        type == "LIST" ||
        type == "DATE" ||
        type == "CURRENCY" ||
        type == "GLOBAL_VARIABLE" ||
        type == "FORMULA"
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleClose(index) {
      this.addStatsData.table_field_group_by_fields.splice(index, 1);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    resetModal() {
      this.openTableColumsModal = false;
    },
    saveTableGroupBy() {
      this.addStatsData.table_field_group_by_fields = [];
      this.addStatsData.table_field_group_by_fields = [
        ...this.tableFieldGroupByFieldsModal,
      ];
      this.openTableColumsModal = false;
    },
    fillLabel(key, index) {
      if (key) {
        if (key == "count") {
          this.tableFieldGroupByFieldsModal[index]["name"] = "Count";
        } else {
          let field = this.getTotalFields.find((e) => e.key == key);
          this.tableFieldGroupByFieldsModal[index]["name"] =
            field && field.label ? field.label : "";
        }
      }
    },
    addNewColumn() {
      this.tableFieldGroupByFieldsModal.push({
        name: "",
        key: "",
      });
    },
    openTableColums() {
      this.tableFieldGroupByFieldsModal = [];
      this.tableFieldGroupByFieldsModal = [
        ...this.addStatsData.table_field_group_by_fields,
      ];
      this.openTableColumsModal = true;
    },
    getParentPath(selectedCategory) {
      if (selectedCategory == "default" || !selectedCategory) {
        if (this.iconColor == "White") {
          return "menuApplicationUsers" + "White";
        } else {
          return "menuApplicationUsers";
        }
      } else {
        if (this.iconColor == "White") {
          return selectedCategory + "-" + "White";
        } else {
          return selectedCategory + "-" + "black";
        }
      }
    },
    getFieldType(key) {
      let selectedField = this.getTemplateFields.find((e) => e.key == key);
      let currencyIncluded = (selectedField?.selected_fields || []).find(
        (e) => e?.validations?.currency || e.currency_type
      );
      let formulaCurrecy = (selectedField?.selected_fields || []).find(
        (e) =>{
          if(e?.input_type=='FORMULA'&&e?.result_type=='CURRENCY'){
            let fc = (e?.selected_fields || []).find(
              (e) => e?.currency_type || e.input_type=='CURRENCY'
            );
            this.addStatsData.currency_type=fc?.currency_type;
            return fc
          }
        }
      );
      if (
        selectedField &&
        (selectedField.inputType == "CURRENCY" ||
          (selectedField.inputType == "AGGREGATE_FUNCTION" &&
            (currencyIncluded || selectedField?.validations?.currency)) ||
          ((selectedField.result_type == "NUMBER" ||
            selectedField.result_type == "CURRENCY") &&
            selectedField.inputType == "FORMULA" &&
            (currencyIncluded || selectedField?.validations?.currency))) &&
        this.addStatsData.display_type == "sum"
      ) {
        this.addStatsData.is_currency = true;
        if (selectedField.validations && selectedField.validations.currency) {
          this.addStatsData.currency_type =
            selectedField.validations.currency || "USD";
        } else if (selectedField?.selected_fields?.[0]?.validations?.currency) {
          this.addStatsData.currency_type =
            selectedField.selected_fields[0].validations.currency || "USD";
        } else if (currencyIncluded) {
          this.addStatsData.currency_type = currencyIncluded || "USD";
        }
      } else if(selectedField?.inputType == "AGGREGATE_FUNCTION"&&formulaCurrecy){
        this.addStatsData.is_currency = true;
      } else {
        this.addStatsData.is_currency = false;
      }
    },
    showNumberFields() {
      // if (this.addStatsData.display_type == "sum") {
      this.fetchTemplateFields(true, true);
      // }
    },
    fetchTemplateFields(reset, isStat = false) {
      if (!this.addStatsData.entity_id) {
        return;
      }
      const entity = this.getAllEntitiesData.find(
        (e) => e._id == this.addStatsData.entity_id
      );
      const templateIds = (entity.templates || [])
        .filter((e) => !e.is_repeatable)
        .flatMap((e) => e.template_id);
      let entityTemplates = [];
      if (this.selectedEntity?.templates) {
        entityTemplates = (this.selectedEntity.templates || [])
          .filter((e) => {
            let tid =
              e.template_id && e.template_id._id
                ? e.template_id._id
                : e.template_id;
            return templateIds.indexOf(tid) !== -1;
          })
          .flatMap((e) => e.templateInfo || e.template_id);
      } else {
        entityTemplates = (
          this.getAllCompanyTemplatesData &&
          this.getAllCompanyTemplatesData.data
            ? this.getAllCompanyTemplatesData.data
            : []
        ).filter((e) => templateIds.indexOf(e._id) !== -1);
      }
      if (reset) {
        this.addStatsData.pie_chart_field = "";
      }
      this.getTemplateFields = [];
      this.getAllDateFields = [];
      this.getTotalFields = [];
      entityTemplates.forEach((e) => {
        let data = JSON.parse(JSON.stringify(e));
        let data1 = JSON.parse(JSON.stringify(e));
        this.getAllDateFields = [
          ...this.getAllDateFields,
          ...this.getTemplateFieldsByType(data1, ["DATE", "DATE_TIME","DATE_RANGE"]),
        ];
        this.getTemplateFields = [
          ...this.getTemplateFields,
          ...this.getTemplateFieldsByType(
            data,
            isStat
              ? ["NUMBER", "CURRENCY", "FORMULA", "AGGREGATE_FUNCTION"]
              : ["SELECT", "YES_OR_NO", "MULTI_SELECT", "ENTITY", "DATE"]
          ),
        ];
        this.getTotalFields = [
          ...this.getTotalFields,
          ...this.getTemplateFieldsByType(data1, [
            "NUMBER",
            "CURRENCY",
            "AGGREGATE_FUNCTION",
          ]),
        ];
      });
    },
    getTemplateFieldsByType(data, types) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      if (types && types.length) {
        fields = fields.filter((e) => (types.indexOf(e.inputType) !== -1) || (e.inputType == 'FORMULA' && types.indexOf(e.result_type) !== -1));
      }
      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    setIconPath(icon) {
      this.addStatsData.icon = icon.url;
      this.iconPickerdialogVisible = false;
    },
    setIcon(icon) {
      this.addStatsData.icon = icon.url;
      this.addStatsData.icon_category = this.selectedCategory;
      this.iconPickerdialogVisible = false;
    },
    getComponentInfo() {
      this.resetFormData();
      this.resetTableFormData();
      if (this.addStatsData.type == "CHART") {
        this.fetchTemplateFields(true);
      } else {
        this.fetchAllEntityFilters(true);
      }
    },
    resetFormData() {
      this.addStatsData.chart_type = "";
      this.addStatsData.pie_chart_field = "";
      this.addStatsData.chart_selected_filter = "";
      this.addStatsData.pie_chart_field_group_by = "";
      this.addStatsData.pie_chart_field_group_by_selected_options = "";
      this.addStatsData.sum_fields = "";
      this.addStatsData.filter_id = "";
    },
    resetTableFormData() {
      this.addStatsData.table_field_group_by = "";
      this.addStatsData.table_field_sub_group_by = "";
    },
    async fetchAllEntityFilters(reset) {
      this.modalLoading = true;
      if (this.selectedEntityRelationalData) {
        this.addStatsData.is_relational_data = true;
        this.addStatsData.entity_id = this.selectedEntityRelationalData;
      } else if(this.selectedEntityNestedRelationalData){
        this.addStatsData.is_nested_relational_data = true;
        this.addStatsData.entity_id = this.selectedEntityNestedRelationalData.split("#")[1]; 
        this.addStatsData.selectedEntityNestedRelationalData = this.selectedEntityNestedRelationalData;     
      } else {
        this.addStatsData.is_relational_data = false;
        this.selectedEntityRelationalData = "";
        this.selectedEntityNestedRelationalData = "";
      }
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: this.addStatsData.entity_id,
      });
      this.statEntityFilters = [];
      this.chartFilters = [];
      if (reset) {
        this.getTemplateFields = [];
        this.addStatsData.filter_id = "";
        this.addStatsData.sum_field = "";
        this.showNumberFields();
      }
      if (this.getAllEntityFilters) {
        this.statEntityFilters = [...this.getAllEntityFilters];
      }
      this.chartFilters = this.getAllEntityFilters;
      this.modalLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-form {
  display: flex;
  flex-direction: column;
}
.formula-dialog .form-right .artboard .btn-variable {
  margin-top: 5px !important;
}
.artboard {
  flex-wrap: wrap !important;
}
.artboard {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #d2d2d2;
  border-radius: 0px;
  padding: 0.5em 1em 0.25em;
  min-height: 60px;
  display: flex;
  align-items: center;
  overflow: scroll;
  width: 100%;
  background: #ffffff;
  .el-tag {
    margin-left: 0.5em;
  }
  .btn-variable {
    background-color: #000577;
    border-radius: 30px;
    color: #ffffff;
    border: #000577;
    padding: 10px 35px 10px 15px;
    position: relative;
    &.active {
      background-color: #dd5050 !important;
      box-shadow: 0 0 0 4px #efefef;
    }
    &:hover {
      box-shadow: 0 0 0 4px #f7f7f7;
    }
    img {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      opacity: 0.7;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  .btn-operation {
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 5px 6px;
    img {
      max-width: 15px;
    }
    &.active {
      border: 2px solid #ff3e3e !important;
      background-color: #f34423 !important;
    }
  }
  .text-button {
    font-size: 4em;
    line-height: 15px;
    span {
      position: relative;
      margin-top: -9px;
      display: block;
      color: #c4c4c4;
    }
  }
  .el-button + .el-button {
    margin: 0 5px;
  }
}
.circleInDialog {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.circle-icon-CD {
  width: 45px;
  height: 43px;
  border-radius: 50%;
  text-align: center;
  margin-top: 10px;
}
.icon-sty {
  margin-top: 8px !important;
}
.circle-icon {
  width: 45px;
  height: 43px;
  border-radius: 50%;
  text-align: center;
  //line-height: 100px;
  //vertical-align: middle;
  margin-top: 10px;
}
// .icon-img-style {
//   margin-top: 10px !important;
//   margin-right: 2px !important;
// }

.operaters {
  text-align: center;
  margin-top: 1em;
  img {
    max-width: 25px;
  }
}
.brackets-btn-group {
  margin-left: 2em;
  .el-button {
    font-size: 2em;
  }
}
</style>
